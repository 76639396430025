import React from "react";
import image1 from "../assets/conference.jpg";
import image2 from "../assets/forum.jpg";
import image3 from "../assets/actualiter.jpg";
import { NavLink } from "react-router-dom";

const caseStudies = [
	{
		id: 1,
		subtiles: "Actualités",
		title: "Retrouver l'actualité Tourisme",
		img: image3,
		link: "/actualiter",
	},
	{
		id: 2,
		subtiles: "Forum",
		title: "Collaborer pour avancer",
		img: image2,
		link: "https://chat.intra2d.fr/home",
	},
	{
		id: 3,
		subtiles: "Visio-Conférence",
		title: "Se réunir pour travailler",
		img: image1,
		link: "https://meet.intra2d.fr",
	},
];

const Cases = () => {
	return (
		<section className="cases">
			<div className="container-fluid">
				<div className="row">
					{caseStudies.map((caseItem) => {
						return caseItem.link !== "/actualiter" ? (
							<a
								className="link-case-item"
								href={caseItem.link}
								key={caseItem.id}>
								<div className="case">
									<div className="case-details">
										<span>{caseItem.subtiles}</span>
										<h2>{caseItem.title}</h2>
									</div>
									<div className="case-image">
										<img src={caseItem.img} alt={caseItem.title} />
									</div>
								</div>
							</a>
						) : (
							<NavLink
								className="link-case-item"
								to={caseItem.link}
								key={caseItem.id}>
								<div className="case">
									<div className="case-details">
										<span>{caseItem.subtiles}</span>
										<h2>{caseItem.title}</h2>
									</div>
									<div className="case-image">
										<img src={caseItem.img} alt={caseItem.title} />
									</div>
								</div>
							</NavLink>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Cases;
