/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import gsap from "gsap";
import "./styles/App.css";

//Component
import Header from "./components/header";
import Navigation from "./components/navigation";

//pages
import Home from "./pages/home";
import Contact from "./pages/contact";
import Actualiter from "./pages/feeds";
import Feed from "./pages/Feed";
import Fourone from "./pages/Fourone";

//routes
const routes = [
	{ path: "/", name: "Home", Component: Home },
	{ path: "/contact", name: "Contact", Component: Contact },
	{ path: "/actualiter", name: "Actualité", Component: Actualiter },
	{ path: "/feed", name: "Feed", Component: Feed },
];

function debounce(fn, ms) {
	let timer;
	timer = setTimeout(() => {
		timer = null;
		fn.apply(this, arguments);
	}, ms);
	clearTimeout(timer);
}

const App = () => {
	//flash blanc
	gsap.to("body", { css: { visibility: "visible" }, duration: 0 });

	const [dimension, setDimension] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	useEffect(() => {
		//saisir la hauteur intérieure de la fenêtre pour les vues mobiles lorsque vous traitez avec vh
		let vh = dimension.height * 0.01;
		//set css variable vh
		document.documentElement.style.setProperty("--vh", `${vh}px`);

		const debounceHandleResize = debounce(function HandleResize() {
			setDimension({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}, 1000);

		window.addEventListener("resize", debounceHandleResize);

		return () => {
			window.removeEventListener("resize", debounceHandleResize);
		};
	});

	return (
		<>
			<Header dimension={dimension} />
			<div className="App">
				<Switch>
					{routes.map(({ path, Component }) => {
						return <Route key={path} exact path={path} component={Component} />;
					})}
					<Route path="*" component={Fourone} />
				</Switch>
			</div>
			<Navigation />
		</>
	);
};

export default App;
