import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
	return (
		<nav>
			<div className="container">
				<div className="nav-columns">
					<div className="nav-column">
						<div className="nav-label">Menu</div>
						<ul className="nav-links">
							<li>
								<NavLink to="/actualiter" exact>
									Actualités
								</NavLink>
							</li>
							<li>
								<a href="https://chat.intra2d.fr/home">Forum</a>
							</li>
							<li>
								<a href="https://cloud.intra2d.fr">Cloud</a>
							</li>
							<li>
								<a href="https://meet.intra2d.fr">Conférences</a>
							</li>
						</ul>
					</div>
					<div className="nav-column">
						<div className="nav-infos">
							<ul className="nav-info">
								<li>
									<NavLink to="/contact" exact>
										Demander ses identifiants à Mathilde
									</NavLink>
								</li>
							</ul>
							<ul className="nav-info">
								<li className="nav-info-label">Mail Administrateur</li>
								<li>
									<a href="mailto:admin@intra2d.fr">admin@intra2d.fr</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navigation;
