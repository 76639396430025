import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as UpArrow } from "../assets/up-arrow-circle.svg";
//import { ReactComponent as LogoC } from '../assets/logo_complet.svg';
import { ReactComponent as Logo } from "../assets/lg.svg";
import { ReactComponent as Txt } from "../assets/txt.svg";
import { openMenu, closeMenu } from "../animations/menuAnimation";

const Header = ({ history, dimension }) => {
	const [menuState, setMenuState] = useState({ menuOpened: false });

	useEffect(() => {
		history.listen(() => {
			setMenuState({ menuOpened: false });
		});

		if (menuState.menuOpened === true) {
			openMenu(dimension.width, dimension.height);
		} else if (menuState.menuOpened === false) {
			closeMenu();
		}
	});

	//const headerBg = menuState.menuOpened ? {background : "transparent",transition: "all 0.3s ease-in-out"} : {background : "#F4F4F4", boxShadow: "0 0 15px rgba(0,0,0,0.05)",transition: "all 0.3s ease-in-out 0.26s"};

	return (
		<div className="header">
			<div className="container">
				<div className="row v-center space-between">
					<a href="/" className="logo">
						<div className="lg">
							<Logo />
						</div>
						<div className="txt">
							<Txt />
						</div>
					</a>
					<div className="nav-toggle">
						<div
							onClick={() => setMenuState({ menuOpened: true })}
							className="hamburger-menu">
							<span></span>
							<span></span>
						</div>
						<div
							onClick={() => setMenuState({ menuOpened: false })}
							className="hamburger-menu-close">
							<UpArrow />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Header);
