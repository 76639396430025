/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { getFeedListing } from "./request";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";

import FeedLoader from "./FeedLoader";
import { GoOnTop } from "../../components/GoOnTop";

const Feed = ({ location, history }) => {
	const [initialized, setInitialized] = useState(false);
	const [url, setUrl] = useState("");
	const [listings, setListings] = useState([]);
	const [data, setData] = useState({});
	const [loader, setLoader] = useState(true);

	const getListings = async (url) => {
		try {
			const response = await getFeedListing(url);
			setListings(response.data.items);
			setData(response.data.feed);
			setLoader(false);
		} catch (ex) {
			console.log(ex);
		}
	};

	useEffect(() => {
		const url = location.state;
		if (!initialized) {
			setUrl(url);
			getListings(url);
			setInitialized(true);
		}
	}, [initialized, location.state]);

	return (
		<div className="page">
			<div className="container">
				{loader ? (
					<div className="feed-page">
						<div className="cases-arrow" onClick={() => history.goBack()}>
							<ArrowLeft />
						</div>
						<h1 className="center-title">Chargement...</h1>
						<div className="feed-container">
							<FeedLoader />
							<FeedLoader />
							<FeedLoader />
							<FeedLoader />
							<FeedLoader />
							<FeedLoader />
							<FeedLoader />
							<FeedLoader />
						</div>
					</div>
				) : (
					<div className="feed-page">
						<div className="cases-arrow" onClick={() => history.goBack()}>
							<ArrowLeft />
						</div>
						<h1 className="center-title">
							{data.image && <img src={data.image} alt="illustration post" />}
							{data.title && data.title}
						</h1>
						<div className="feed-container">
							{listings.map((l, i) => {
								return (
									<div className="card-feed" key={i}>
										<div className="card-title">{l.title}</div>
										<div className="card-body">
											{l.enclosure.link && (
												<img src={l.enclosure.link} alt={l.author} />
											)}
											<p dangerouslySetInnerHTML={{ __html: l.description }} />
										</div>
										<div className="card-footer">
											<a href={l.link} rel="noreferrer" target="_blank">
												Voir l'article complet
											</a>
											<div className="infos">
												{l.pubDate && (
													<p>
														Date : <strong>{l.pubDate}</strong>
													</p>
												)}
												{l.author && (
													<p>
														<strong>{l.author}</strong>
													</p>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<GoOnTop />
					</div>
				)}
			</div>
		</div>
	);
};

export default Feed;
