import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import axios from "axios";
const apiKey = process.env.REACT_APP_API_KEY;

const Contact = () => {
	const [emailData, setEmailData] = useState({ show: false, msg: "" });
	const { register, handleSubmit, setError, setValue, formState } = useForm({
		mode: "onChange",
	});

	const { isValid, errors } = formState;

	function handleEmailSend(msg) {
		let timer;
		setEmailData({
			show: true,
			msg,
		});
		timer = () => {
			setEmailData({
				show: false,
				msg,
			});
			clearTimeout(setTimeout(timer));
		};
		setTimeout(timer, 3000);
	}

	const onSubmit = (d) => {
		const { name, email, opt } = d;
		if (opt === "none" || opt === "") {
			setError("opt", {
				type: "manual",
				message: "Vous devez choisir une option",
			});
			setValue("name", "");
			setValue("email", "");
		} else {
			const data = {
				name,
				email,
				opt,
			};
			axios
				.post("https://api.intra2d.fr/api/contact/newEmail", data, {
					headers: {
						"X-API-KEY": apiKey,
					},
				})
				.then((res) => {
					if (res.data.error) {
						setError("opt", {
							type: "manual",
							message: res.data.message,
						});
						setValue("name", "");
						setValue("email", "");
						setValue("opt", "");
					} else {
						setValue("name", "");
						setValue("email", "");
						setValue("opt", "");
						handleEmailSend(res.data.message);
					}
				})
				.catch((err) => {
					if (err) {
						setError("opt", {
							type: "manual",
							message:
								"problème avec le server, veuillez réessayer plus tard..",
						});
					}
				});
		}
	};

	if (!emailData.show) {
		return (
			<div className="page">
				<div className="container">
					<div className="row">
						<div className="content-form">
							<h1>Contact</h1>
							<form onSubmit={handleSubmit(onSubmit)}>
								<h2>Formulaire de demande d'inscription</h2>
								<div className="form-group">
									<label htmlFor="name">Nom</label>
									<input
										type="text"
										name="name"
										id="name"
										ref={register({ required: "Vous devez rentrez un nom" })}
									/>
									{errors.name && <span>{errors.name.message}</span>}
								</div>
								<div className="form-group">
									<label htmlFor="email">Email</label>
									<input
										type="email"
										name="email"
										id="email"
										ref={register({ required: "Vous devez entrez un email" })}
									/>
									{errors.email && <span>{errors.email.message}</span>}
								</div>
								<div className="form-group">
									<label htmlFor="opt">Demande d'accès pour</label>
									<select
										className="select-inp"
										name="opt"
										id="opt"
										ref={register({ required: "Veuillez choisir une option" })}>
										<option value="none" hidden>
											Selectionner une option
										</option>
										<option value="La viso-conférence">
											La visio-conférence
										</option>
										<option value="Le forum">Le Forum</option>
										<option value="Le Cloud">Le Cloud</option>
									</select>
									{errors.opt && <span>{errors.opt.message}</span>}
								</div>
								{!isValid ? (
									<button type="submit" className="btn btn-disable" disabled>
										Envoyer
									</button>
								) : (
									<button type="submit" className="btn btn-primary">
										Envoyer
									</button>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="page">
				<div className="container">
					<div className="row">
						<div className="content-email">
							<CheckCircleIcon className="icon-valid" />
							<h3>{emailData.msg}</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default Contact;
