import React from 'react';

const Banner = () => {
    return(
        <section className="main">
            <div className="container">
                <div className="row">
                    <h2>
                        <div className="line">
                            <span>Le réseau Intranet pour</span>
                        </div>
                        <div className="line">
                            <span>nous faciliter la vie.</span>
                        </div>
                    </h2>
                </div>
            </div>
        </section>
    )
}

export default Banner;