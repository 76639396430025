import React from 'react'

const Fourone = () => {
    return (
        <div className="page">
            <div className="container">
                <div className="row">
                    <h3>Page introuvable, erreur 404</h3>
                </div>
            </div>
        </div>
    )
}

export default Fourone
