import React, { useEffect } from "react";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";

export const GoOnTop = () => {
	useEffect(() => {
		const scroll = () => {
			const goBtn = document.querySelector(".goTop");
			const y = window.scrollY;
			if (y > 150) {
				goBtn.style.display = "flex";
			} else {
				goBtn.style.display = "none";
			}
		};

		window.addEventListener("scroll", scroll);

		return () => window.removeEventListener("scroll", scroll);
	});

	const handleClick = (h) => {
		if (h >= 0) {
			setTimeout(() => {
				window.scroll(0, h);
				handleClick(h - 100);
			}, 10);
		} else {
			window.scroll(0, 0);
		}
	};

	return (
		<div className="goTop" onClick={() => handleClick(window.scrollY)}>
			<ArrowLeft />
		</div>
	);
};
