import React from "react";
import { ReactComponent as Loading } from "../../assets/loader.svg";

const FeedLoader = () => {
	return (
		<div className="card-feed">
			<div className="card-body">
				<div
					style={{
						width: "100%",
						height: "300px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<Loading />
				</div>
			</div>
		</div>
	);
};

export default FeedLoader;
