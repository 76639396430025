import gsap from "gsap";

let tl = gsap.timeline();

export const openMenu = (width, height) => {
	tl.to("nav", {
		css: { display: "block" },
		duration: 0,
	})
		.to("body", { css: { overflow: "hidden" }, duration: 0 })
		.to(".App", {
			y:
				width <= 654 || height <= 855
					? "100vh"
					: width <= 768
					? window.innerHeight / 1.7
					: window.innerHeight / 1.6,
			ease: "expo.inOut",
			duration: 0.9,
		})
		.to(".hamburger-menu span", {
			delay: -1,
			scaleX: 0,
			transformOrigin: "50% 0%",
			ease: "expo.inOut",
			duration: 0.6,
		})
		.to("#Path_1", {
			delay: -0.6,
			css: {
				strokeDashoffset: 10,
				strokeDasharray: 5,
			},
			duration: 0.4,
		})
		.to("#Path_2", {
			delay: -0.6,
			css: {
				strokeDashoffset: 10,
				strokeDasharray: 20,
			},
			duration: 0.4,
		})
		.to("#Line_1", {
			delay: -0.6,
			css: {
				strokeDashoffset: 40,
				strokeDasharray: 18,
			},
			duration: 0.4,
		})
		.to("#circle", {
			delay: -0.8,
			css: {
				strokeDashoffset: 0,
			},
			ease: "expo.inOut",
			duration: 0.6,
		})
		.to(".hamburger-menu-close", {
			delay: -0.8,
			css: { display: "block" },
			duration: 0.6,
		});
};

export const closeMenu = () => {
	tl.to(".App", {
		y: 0,
		ease: "expo.inOut",
		duration: 0.6,
	})
		.to("#circle", {
			delay: -0.6,
			css: {
				strokeDashoffset: -193,
				strokeDasharray: 227,
			},
			duration: 0.6,
		})
		.to("#Path_1", {
			delay: -0.6,
			css: {
				strokeDashoffset: 10,
				strokeDasharray: 10,
			},
			duration: 0.4,
		})
		.to("#Path_2", {
			delay: -0.6,
			css: {
				strokeDashoffset: 10,
				strokeDasharray: 10,
			},
			duration: 0.4,
		})
		.to("#Line_1", {
			delay: -0.6,
			css: {
				strokeDashoffset: 40,
				strokeDasharray: 40,
			},
			duration: 0.4,
		})
		.to(".hamburger-menu span", {
			delay: -0.6,
			scaleX: 1,
			transformOrigin: "50% 0%",
			ease: "expo.inOut",
			duration: 0.6,
		})
		.to(".hamburger-menu-close", {
			delay: -0.1,
			css: { display: "none" },
			duration: 0,
		})
		.to("body", { css: { overflow: "auto" }, duration: 0 })
		.to("nav", {
			css: { display: "none" },
			duration: 0,
		});
};
