import React from "react";

const Actualiter = ({ history }) => {
	const handleClick = (url) => {
		history.push("/feed", url);
	};
	return (
		<div className="page">
			<div className="container">
				<div className="row">
					<div className="actualiter">
						<h1>Actualités</h1>
						<div className="categorie">
							<div className="categorie_col">
								<h2
									onClick={() =>
										handleClick("https://www.tourmag.com/xml/syndication.rss")
									}>
									TourMag
								</h2>
								<h2
									onClick={() =>
										handleClick("https://www.veilleinfotourisme.fr/rss.xml")
									}>
									Infos Tourisme
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/2/feed"
										)
									}>
									Politique nationale du tourisme
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/3/feed"
										)
									}>
									Observatoire économique
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/4/feed"
										)
									}>
									Entreprises et clientèles
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/11/feed"
										)
									}>
									Juridique
								</h2>
							</div>
							<div className="categorie_col">
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/5/feed"
										)
									}>
									Régions et territoires
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/6/feed"
										)
									}>
									Union européenne
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/7/feed"
										)
									}>
									International
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://www.veilleinfotourisme.fr/thematiques/8/feed"
										)
									}>
									Tendances et recherches en tourisme
								</h2>
								<h2
									onClick={() =>
										handleClick(
											"https://france3-regions.francetvinfo.fr/economie/tourisme/rss"
										)
									}>
									France3 Régions
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Actualiter;
