/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect } from 'react';
import { useCookies } from "react-cookie";
import gsap from 'gsap';
import IntroOverlay from '../components/introOverlay';
import Banner from '../components/banner';
import Cases from '../components/cases';

const tl = gsap.timeline();
const homeAnimation = (completeAnimation) => {
    tl.from('.line span', {
        y: 110,
        ease: 'power4.out',
        delay: 1,
        skewY: 7,
        stagger: {
          amount: 0.3
        },
        duration: 1.8,
      }).to('.overlay-top', {
        height: 0,
        ease: "expo.inOut",
        stagger : 0.4,
        duration: 1.6,
      }).to('.overlay-bottom', {
        width: 0,
        ease: "expo.inOut",
        delay: -0.8,
        stagger : {
          amount: 0.4
        },
        duration: 1.6,
      }).to('.intro-overlay', { css: { display: "none" }, duration: 0, })
      .from('.case-image img', {
        duration: 1.6,
        scale: 1.4,
        ease: "expo.inOut",
        delay: -2,
        stagger : {
          amount: 0.4
        },
        onComplete: completeAnimation
      })
}

const Home = () => {
  const [cookies, setCookie] = useCookies(["animation"]);
    const completeAnimation = () => {
        setCookie("animation","true");

    }

    useEffect(() => {
        if(cookies.animation === undefined){
          homeAnimation(completeAnimation);
        }
    }, [])

    return (
        <>
            {cookies.animation === undefined && <IntroOverlay/>}
            <Banner/>
            <Cases/>
        </>
    )

}

export default Home;