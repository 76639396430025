import React from "react";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
	<CookiesProvider>
		<Router>
			<App />
		</Router>
	</CookiesProvider>,
	document.getElementById("root")
);

reportWebVitals();
